<template>
	<div class="content-wrapper bg-white">
		<header class="d-flex align-items-center header pt-3 px-2 ml-2 justify-content-between">
			<div class="d-flex fa-lg align-items-center">
        <i class="mb-2 font-color-custom text-muted fas fa-flask " />
        <h3 class="text-muted ml-2">Solicitações de Trial</h3>
      </div>

      <input
        type="search"
        class="form-control col-4"
        placeholder="Buscar pelo nome, email e CNPJ do trial"
        :disabled="loadingData"
        @input="debounceTrialSearch"
        v-model="searchTrial"
      />
		</header>

		<main class="mt-3 d-flex flex-column">
			<div class="card-body table-responsive p-0">
				<table class="table table-hover table-stripped table-head-fixed">
					<thead>
						<tr>
							<th>ID</th>
							<th>Nome</th>
							<th>CNPJ</th>
							<th>Email</th>
							<th class="justify-content-center">Ações</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="trial in trialList" :key="trial._id">
							<td>{{ trial._id }}</td>
							<td>{{ trial.name }}</td>
							<td>{{ trial.cnpj }}</td>
							<td>{{ trial.email }}</td>
							<td>
								<button class="btn btn-outline-danger btn-sm ml-1" @click="deleteTrial(trial._id)">
									<i class="fa fa-trash"></i>
								</button>
							</td>
						</tr>
					</tbody>
				</table>
        <section class="d-flex justify-content-center mb-3">
          <nav class="mt-3 d-flex">
            <button :disabled="pagination.current === 1 || loadingData" class="btn btn-outline-primary btn-pagination" @click="firstPage()">
              <i class="fa fa-angle-double-left" aria-hidden="true"></i>
            </button>
            <button :disabled="pagination.current === 1 || loadingData" class="btn btn-outline-primary btn-pagination" @click="previousPage()">
              <i class="fa fa-angle-left" aria-hidden="true"></i>
            </button>

            <select
              class="form-control mx-2"
              v-model="pagination.current"
              :disabled="loadingData"
            >
              <option
                :key="i"
                v-for="i in pagination.lastPage"
                :value="i"
              >
                Página {{ i }}
              </option>
            </select>

            <button :disabled="pagination.current === pagination.lastPage || loadingData" class="btn btn-outline-primary btn-pagination" @click="nextPage()">
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </button>
            <button :disabled="pagination.current === pagination.lastPage || loadingData" class="btn btn-outline-primary btn-pagination" @click="lastPage()">
              <i class="fa fa-angle-double-right" aria-hidden="true"></i>
            </button>
          </nav>
        </section>
			</div>
		</main>
	</div>
</template>

<script>
export default {
	data() {
		return {
      loadingData: false,
      timeoutSearch: null,
      searchTrial: '',
			trialList: [],
			pagination: {
				current: 1,
				lastPage: 0,
			},
		};
	},

	methods: {
		getTrialCompanies() {
      this.loadingData = true;

			this.$tallos_admin_api.defaults.headers.common['Authorization'] = `Bearer ${this.$store.getters.tokenJWT}`;

			this.$tallos_admin_api
				.get(`trial/?page=${this.pagination.current}&trial_search=${this.searchTrial}`)
				.then((res) => {
					this.trialList = res.data.docs;
					this.pagination.lastPage = res.data.pages;
				})
				.catch(() => {
					this.$toasted.global.defaultError({ msg: 'Erro ao buscar companhias em trial.' });
				})
        .finally(() => this.loadingData = false);
		},
		deleteTrial(id) {
			let removePost = confirm('Deseja remover este trial?');

			if (removePost == true) {
				this.$tallos_admin_api.delete('trial/' + id).then((res) => this.getTrialCompanies());
				this.$toasted.global.defaultSuccess({ msg: `Trial excluído` });
			}
		},

    debounceTrialSearch() {
      clearTimeout(this.timeoutSearch)

      this.timeoutSearch = setTimeout(this.getTrialCompanies, 1000)
    },

		changePage(page) {
			this.pagination.current = page;
		},

		firstPage() {
			this.pagination.current = 1;
		},

		lastPage() {
			this.pagination.current = this.pagination.lastPage;
		},

		previousPage() {
			if (this.pagination.current > 1) this.pagination.current--;
		},

		nextPage() {
			if (this.pagination.current < this.pagination.lastPage) this.pagination.current++;
		},
	},

	watch: {
		'pagination.current'() {
			this.getTrialCompanies();
		},
	},

	mounted() {
		this.getTrialCompanies();
	},
};
</script>
<style scoped>
  .btn-pagination {
    border-radius: 0 !important;
  }
</style>
